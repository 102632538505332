import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { IUserActivity, UserActivity } from '@/shared/model/user-activity.model';

const baseApiUrl = 'api/private/user-activities';

export default class SettingService {
  public find(id: number): Promise<IUserActivity> {
    return new Promise<IUserActivity>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public createEvent(account: any, location: any, action: string, verb: string): Promise<IUserActivity>{
    let ip = location ? location.ip : null;
    const longitute = location ? location.longitude : null;
    const latitude = location ? location.latitude : null;
    const code2  = location ? location.country_code2 : null;
    const code3  = location ? location.country_code3 : null;
    const country  = location ? location.country_name : null;
    const flag  = location ? location.country_flag : null;

    if (ip && ip.length > 20) {
      ip = null;
    }
    const userActivity = new UserActivity(null, action, new Date(), ip, latitude, longitute, account.applicationUserId, verb, code2, code3, country, flag );
    return this.create(userActivity);
  }

  public create(entity: IUserActivity): Promise<IUserActivity> {
    return new Promise<IUserActivity>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
