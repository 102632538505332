import axios from 'axios';

const baseApiUrl = 'api/private/';

export default class ProfileService {
  public updateProfile(entity: any) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}account`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
      });
    });
  }

  public changePassword(currentPassword: string, newPassword: string) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}account/change-password`, {
          currentPassword,
          newPassword,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public changeAvatar(id: number, file: any) {
    const options = {
      headers: {'Content-Type': 'multipart/form-data'}
    };
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}account/users/${id}/avatar`, file, options)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // public find(id: number): Promise<any> {
  //   return new Promise<IPlan>((resolve, reject) => {
  //     api
  //       .get(`${baseApiUrl}/${id}`)
  //       .then(res => {
  //         resolve(res.data);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // }

  // public retrieve(paginationQuery?: any): Promise<any> {
  //   return new Promise<any>((resolve, reject) => {
  //     api
  //       .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
  //       .then(res => {
  //         resolve(res);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // }

  // public delete(id: number): Promise<any> {
  //   return new Promise<any>((resolve, reject) => {
  //     api
  //       .delete(`${baseApiUrl}/${id}`)
  //       .then(res => {
  //         resolve(res);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // }

  // public create(entity: IPlan): Promise<IPlan> {
  //   return new Promise<IPlan>((resolve, reject) => {
  //     api
  //       .post(`${baseApiUrl}`, entity)
  //       .then(res => {
  //         resolve(res.data);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // }

  // public update(entity: IPlan): Promise<IPlan> {
  //   return new Promise<IPlan>((resolve, reject) => {
  //     api
  //       .put(`${baseApiUrl}`, entity)
  //       .then(res => {
  //         resolve(res.data);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // }
}
