export enum Authority {
  ADMIN = 'ROLE_ADMIN',
  USER = 'ROLE_USER',
  AUTHOR = 'ROLE_AUTHOR',
  STUDENT = 'ROLE_STUDENT',
  INSTRUCTOR = 'ROLE_INSTRUCTOR',
  ORG_ADMIN = 'ROLE_ADMINISTRATOR_ORG',
  REPORTER = 'ROLE_REPORTER',
  ADMINISTRATOR = 'ROLE_ADMINISTRATOR',
  FULFILLMENT = 'ROLE_FULFILLMENT',
  ACCOUNT_MANAGER = 'ROLE_ACCOUNT_MNGR',
  FULFILLMENT_RO = 'ROLE_FULFILLMENT_RO',
  RESELLER = 'ROLE_RESELLER'
}

export function getAuthorityFromImport(role) {
  switch(role.toLowerCase()) {
    case 'admin':
    case 'org admin':
      return 'Org Administrator';
    case 'author':
      return 'Author';
    case 'manager':
    case 'account manager':
      return 'Account Manager';
    case 'learner':
    case 'student':
      return 'Learner';
  }
}

export function getAuthorityToName(role) {
  switch(role) {
    case 'ROLE_ADMINISTRATOR_ORG': return 'Org Administrator';
    case 'ROLE_ADMINISTRATOR': return 'Administrator';
    case 'ROLE_AUTHOR': return 'Author';
    case 'ROLE_FULFILLMENT': return 'Fulfillment';
    case 'ROLE_ACCOUNT_MNGR': return 'Account Manager';
    case 'ROLE_REPORTER': return 'Reporter';
    case 'ROLE_USER': return 'User';
    case 'ROLE_STUDENT': return 'Learner';
    case 'ROLE_INSTRUCTOR': return 'Instructor';
    case 'ROLE_PROJECT_MANAGER': return 'Project Manager';
    case 'ROLE_FULFILLMENT_RO': return 'Fulfillment Read Only';
    case 'ROLE_RESELLER': return 'Reseller';

  }
  const roles: any[] = [];
  roles.push({
    name: 'Account Manager',
    value: Authority.ACCOUNT_MANAGER
  });

  return roles;
}

export function getLimitAuthorityMapped() {
  const roles: any[] = [];
  roles.push({
    name: 'Account Manager',
    value: Authority.ACCOUNT_MANAGER
  });

  return roles;
}

export function getAuthorityReseller() {
  const roles: any[] = [];


  roles.push({
    name: 'Reseller',
    value: Authority.RESELLER
  });

  return roles;

}

export function getLearnerAuthorityMapped() {
  const roles: any[] = [];
  roles.push({
    name: 'Learner',
    value: Authority.STUDENT
  });

  return roles;
}


export function getAuthorAuthorityMapped() {
  const roles: any[] = [];

  roles.push({
    name: 'Org Administrator',
    value: Authority.ORG_ADMIN
  });

  roles.push({
    name: 'Author',
    value: Authority.AUTHOR
  });

  roles.push({
    name: 'Account Manager',
    value: Authority.ACCOUNT_MANAGER
  });

  return roles;
}


export function getAuthorityMapped() {
  const roles: any[] = [];

  roles.push({
    name: 'Account Manager',
    value: Authority.ACCOUNT_MANAGER
  });


  roles.push({
    name: 'Adminstrator',
    value: Authority.ADMINISTRATOR
  });


  roles.push({
    name: 'Author',
    value: Authority.AUTHOR
  });

  roles.push({
    name: 'Fulfillment',
    value: Authority.FULFILLMENT
  });

  roles.push({
    name: 'Fulfillment Read Only',
    value: Authority.FULFILLMENT_RO
  });

  roles.push({
    name: 'Org Administrator',
    value: Authority.ORG_ADMIN
  });

  roles.push({
    name: 'Reseller',
    value: Authority.RESELLER
  });

  roles.push({
    name: 'Reporter',
    value: Authority.REPORTER
  });

  // roles.push({
  //   name: 'Learner',
  //   value: Authority.STUDENT
  // });

  return roles;

}
