var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-app",
    { class: _vm.background },
    [
      _vm.reAuthentication ? _c("reauthenticate") : _vm._e(),
      _vm._v(" "),
      _c(_vm.layout, { tag: "component" }, [_c("router-view")], 1),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }