import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import AlertMixin from '@/shared/alert/alert.mixin';
import { mixins } from 'vue-class-component';
import SigninService from './signin.service';
import { REFRESH_TOKEN, TOKEN } from '@/constants';
import { utils } from '@/shared/utils';
import UserActivityService from '@/entities/user-activity/user-activity.service';
import { IApplicationUser } from '@/shared/model/application-user.model';
import jwt_decode from 'jwt-decode';

@Component({
  mixins: [AlertMixin],
  components: {},
})
export default class Reauthenticate extends mixins(AlertMixin) {
  @Inject('signinService')
  private signinService: () => SigninService;

  @Inject('userActivityService')
  private userActivityService: () => UserActivityService;

  public settingsAccount: IApplicationUser = {};
  public authenticationError = null;
  public loading = false;
  public password = null;
  public hidden = true;
  userID: number = null;
  reauthCount: any = null;

  created(): void {
    this.getAccount();
    if (this.$route.path === '/') {
      this.$store.commit('setReAuthentication', false);
    }
  }
  public getAccount() {
    this.loading = false;
    const token = localStorage.getItem(TOKEN);
    this.settingsAccount.imageUrl = localStorage.getItem('imageUrl');
    const decodedToken = jwt_decode(token);
    if (decodedToken) {
      this.settingsAccount.userLogin = decodedToken['email'];
    }
    localStorage.removeItem(TOKEN);
    sessionStorage.removeItem(TOKEN);
    localStorage.removeItem('exp');
  }
  public doLogin(): void {
    if (this.password == null) {
      this.authenticationError = true;
      return;
    }
    this.loading = true;
    const data = { username: this.settingsAccount.userLogin, password: this.password };
    this.signinService()
      .signin(data)
      .then((result) => {
        const bearerToken = result.id_token;
        const refresToken = result.refreshToken;

        this.$store.commit('setReAuthentication', false);
        this.reauthCount = parseInt(localStorage.getItem('reauthCount'));
        if (this.reauthCount) {
          localStorage.setItem('reauthCount', this.reauthCount + 1);
        } else {
          localStorage.setItem('reauthCount', '1');
        }

        if (bearerToken) {
          localStorage.setItem(TOKEN, bearerToken);
          const decodedToken = utils.decodeToken(bearerToken);
          if (decodedToken) {
            localStorage.setItem('exp', decodedToken['exp']);
          }
        }

        if (refresToken) {
          localStorage.setItem(REFRESH_TOKEN, refresToken);
        }
        this.userActivityService().createEvent(
          this.$store.getters.account,
          this.$store.getters.currentLocation,
          'portal login',
          'loggedin'
        );

        this.authenticationError = false;
        window.location.reload();
      })
      .catch((error) => {
        let msg = 'The password you entered is incorrect.';
        if (error.response && error.response.data && error.response.data.detail) {
          msg = error.response.data.detail;
        }
        this.showAlert(msg);
      })
      .finally(() => {
        this.loading = false;
      });
  }
  public showAlert(msg) {
    this.$swal
      .fire({
        icon: 'error',
        title: 'Sign In Error',
        confirmButtonColor: '#000C19',
        text: msg,
        confirmButtonText: 'Switch User',
      })
      .then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem('reauthCount');
          localStorage.removeItem(TOKEN);
          sessionStorage.removeItem(TOKEN);
          localStorage.removeItem('exp');
          window.location.reload();
        }
      });
  }
}
