import { Module } from 'vuex';

export const stripeStore: Module<any, any> = {
  state: {
    allPlans: localStorage.getItem('allPlans') || null,
  },
  getters: {
    allPlans(state) {
      if (state.allPlans) {
        return JSON.parse(state.allPlans);
      }

      return null;
    }
  },
  mutations: {
    allPlans(state, allPlans) {
      state.allPlans = allPlans;
      localStorage.setItem('allPlans', allPlans);
    },
  },
};
