import { IElbSubscription } from './elb-subscription.model';
import { ISetting } from './setting.model';


export const TAX_STATUS = ['Taxable', 'Exempt'];


export interface IOrganization {
  id?: number;
  name?: string;
  description?: string;
  ldOffline?: boolean;
  instanceName?: string;
  metadata?: any;
  notes?: string;
  subscriptions?: IElbSubscription[];
  type?: string;
  parentId?: number;
  settings?: ISetting[];
  domainName?: string;
  taxStatus?: string;
  eulaUser?: string;
  eulaDate?: Date;
}

export class Organization implements IOrganization {
  constructor(
    public id?: number,
    public name?: string,
    public description?: string,
    public ldOffline?: boolean,
    public instanceName?: string,
    public metadata?: any,
    public notes?: string,
    public subscriptions?: IElbSubscription[],
    public type?: string,
    public parentId?: number,
    public settings?: ISetting[],
    public domainName?: string,
    public taxStatus?: string,
    public eulaUser?: string,
    public eulaDate?: Date
  ) {}
}
