// These constants are injected via webpack environment variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application

export const VERSION = process.env.VERSION;
// export const DEBUG_INFO_ENABLED: boolean = !!process.env.DEBUG_INFO_ENABLED;
export const SERVER_API_URL = process.env.SERVER_API_URL;
export const BUILD_TIMESTAMP = process.env.BUILD_TIMESTAMP;
export const CLIENT_ID = process.env.CLIENT_ID; // 'spa-service';
export const IDP_BASE_URL = process.env.IDP_BASE_URL; // 'http://localhost:8080/auth/realms/dev';
export const TOKEN = 'elb-authenticationToken';
export const REFRESH_TOKEN = 'elb-rt';
export const STRIPE_KEY = process.env.STRIPE_KEY;
export const HS_BASE_URL = process.env.HS_BASE_URL;
export const TTA_GAMES_URL = process.env.TTA_GAMES_URL;
export const REGISTER_URL = process.env.REGISTER_URL;
export const INSIGTHS_URL = process.env.INSIGTHS_URL;

// Errors
export const PROBLEM_BASE_URL = 'https://www.jhipster.tech/problem';
export const EMAIL_ALREADY_USED_TYPE = PROBLEM_BASE_URL + '/email-already-used';
export const LOGIN_ALREADY_USED_TYPE = PROBLEM_BASE_URL + '/login-already-used';
export const ORGANIZATION_ALREADY_USED_TYPE = PROBLEM_BASE_URL + '/org-name-already-used';
