import { Store } from 'vuex';
import axios from 'axios';
import { EventBus } from '@/shared/config/event-bus';
import { utils } from '@/shared/utils';
import { th } from 'date-fns/locale';

export default class AsyncService {
  private store: Store<{}>;

  constructor(store: Store<{}>) {
    this.store = store;
    this.store.commit('initAsync');
  }

  public resetStore() {
    this.store.commit('initAsync');
  }

  public setAsync(data: any) {
    this.store.commit('setAsync', data);
  }

  public setStatus(status: any) {
    this.store.commit('setStatus', status);
  }

  public setRunning(running: boolean) {
    this.store.commit('setRunning', running);
  }

  public setPercentage(percentage: any) {
    this.store.commit('setPercentage', percentage);
  }

  public startAsyncTask({ taskId, taskName, taskPendingString, overlay }) {
    this.setAsync({
      taskStatus: 'started',
      taskName,
      taskId,
      taskPendingString,
      overlay
    });
    return this.pollAsyncTaskStatus();
  }

  async pollAsyncTaskStatus() {
    let done = false;
    let response = null;
    let counter = 0;
    while (!done) {
      await utils.wait(5000, null);
      const req = axios.get(`api/private/async-jobs/${this.store.getters.taskId}`);
      response = await req;
      this.setRunning(response.data.status);
      this.setPercentage(response.data.percentageComplete);
      EventBus.$emit('task-updated', response.data);
      done = response.data.status === 'completed' || response.data.status === 'failed' || counter > this.store.getters.maxRequests;
      counter++;
    }

    this.setRunning(false);
    EventBus.$emit('task-finnished', response.data);
    return response.data;
  }
}
