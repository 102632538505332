import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { IDashboardReq } from '@/shared/model/dashboard-req.model';
import { IReportRequest } from '@/shared/model/report-request.model';

const baseApiUrl = 'api/private/dashboard';

export default class DashboardService {

  public countOrganizations(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios.get(baseApiUrl + `/organizations/count?${buildPaginationQueryOpts(paginationQuery)}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  public countUsers(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios.get(baseApiUrl + `/application-users/count?${buildPaginationQueryOpts(paginationQuery)}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  public countSubscriptions(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios.get(baseApiUrl + `/subscriptions/count?${buildPaginationQueryOpts(paginationQuery)}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public export(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/export?${buildPaginationQueryOpts(paginationQuery)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  //
  public getSubscriptionData(dto: IDashboardReq): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios.post(baseApiUrl + '/subscriptions', dto,  {responseType: 'blob'} )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  public getSubscriptionHistory(dto: IDashboardReq): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios.post(baseApiUrl + '/subscription-histories', dto)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  public getSubscriptionCount(dto: IDashboardReq): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios.post(baseApiUrl + '/subscriptions/count', dto)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  public getResellerSubscriptionCount(dto: IDashboardReq): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios.post(baseApiUrl + '/subscriptions/resellers/count', dto)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  public aggregation(dto: IReportRequest): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios.post(baseApiUrl + '/aggregation', dto)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  public aggregationData(dto: IReportRequest): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios.post(baseApiUrl + '/aggregation/data', dto)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  // dashboard/aggregation/data

}
