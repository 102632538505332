var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.showLegal
    ? _c(
        "v-container",
        {
          staticStyle: {
            position: "absolute",
            "z-index": "9",
            "backdrop-filter": "blur(5px)",
          },
          attrs: { "fill-height": "", fluid: "" },
        },
        [
          _c(
            "v-row",
            {
              staticClass: "mt-n16",
              attrs: { align: "center", justify: "center" },
            },
            [
              _c(
                "v-col",
                { attrs: { align: "center", justify: "center" } },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        "max-width": "600px",
                        "min-height": _vm.isAdmin ? "500px" : "520px",
                        color: "#f5f5f5",
                      },
                    },
                    [
                      _c(
                        "v-card-title",
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "mt-4",
                              attrs: { align: "center", justify: "center" },
                            },
                            [
                              _c("img", {
                                attrs: {
                                  width: "145",
                                  height: "50",
                                  src: "/content/images/TheStudio_Logo_Lockup-Stacked_Dark.svg",
                                },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { staticClass: "mt-6" },
                        [
                          _c(
                            "v-col",
                            [
                              _vm.notAdminAndReminderSent
                                ? _c(
                                    "v-card",
                                    {
                                      staticClass: "pa-md-4 mx-lg-auto",
                                      attrs: {
                                        outlined: "",
                                        "max-width": "500px",
                                        align: "start",
                                        justify: "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-col", [
                                            _c(
                                              "h3",
                                              {
                                                staticClass: "font-weight-bold",
                                              },
                                              [
                                                _vm._v(
                                                  "We have informed all administrators and are awaiting their acceptance."
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-col", [
                                            _c("span", [
                                              _vm._v(
                                                "Your organization's administrator needs to accept the terms and conditions for your access to be restored."
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("p", { staticClass: "mt-4" }, [
                                              _vm._v(
                                                "We apologize for any inconvenience this may cause and appreciate your patience. Once the admin completes this process, you will be able to continue using the application as usual."
                                              ),
                                            ]),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "entity-button-text",
                                                  attrs: {
                                                    block: "",
                                                    color: "primary",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: { small: "" },
                                                    },
                                                    [_vm._v("mdi-refresh")]
                                                  ),
                                                  _vm._v("Check again"),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _c(
                                    "v-card",
                                    {
                                      staticClass: "pa-md-4 mx-lg-auto",
                                      attrs: {
                                        outlined: "",
                                        "max-width": "500px",
                                        align: "start",
                                        justify: "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-col", [
                                            _c(
                                              "h3",
                                              {
                                                staticClass: "font-weight-bold",
                                              },
                                              [
                                                _vm._v(
                                                  "ELB Learning Terms of Services"
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        { staticClass: "mt-n6" },
                                        [
                                          _c("v-col", [
                                            _c(
                                              "span",
                                              [
                                                _vm._v(
                                                  "Please review and accept the following "
                                                ),
                                                _vm._l(
                                                  _vm.legalContentList,
                                                  function (legal, index) {
                                                    return _c(
                                                      "span",
                                                      { key: index },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            staticStyle: {
                                                              color: "#1D4ED8",
                                                            },
                                                            attrs: {
                                                              href: legal.link,
                                                              target: "_blank",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                legal.context
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        index <
                                                        _vm.legalContentList
                                                          .length -
                                                          1
                                                          ? _c("span", [
                                                              _vm._v(" and "),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                _vm._v("."),
                                              ],
                                              2
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        [
                                          _c("v-col", [
                                            _c("span", [
                                              _c("strong", [
                                                _vm._v("Account:"),
                                              ]),
                                              _vm._v(
                                                " " +
                                                  _vm._s(_vm.oganizationName)
                                              ),
                                            ]),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        [
                                          !_vm.isAdmin
                                            ? _c(
                                                "v-row",
                                                { staticClass: "mt-2" },
                                                [
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c(
                                                        "v-alert",
                                                        {
                                                          attrs: {
                                                            type: "warning",
                                                            outlined: "",
                                                            text: "",
                                                            color: "#E49B0F",
                                                            icon: "mdi-alert",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "You must be an admin to accept the terms."
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        { staticClass: "mt-6" },
                                        [
                                          _vm.isAdmin
                                            ? _c("v-col", [
                                                _c("span", [
                                                  _vm._v(
                                                    'By selecting "Accept" you agree to the ELB Learning Terms and Conditions for ' +
                                                      _vm._s(
                                                        _vm.oganizationName
                                                      ) +
                                                      "."
                                                  ),
                                                ]),
                                              ])
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-row",
                                        { staticClass: "mb-2" },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _vm.isAdmin
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "entity-button-text",
                                                      attrs: {
                                                        block: "",
                                                        color: "primary",
                                                        disabled: !_vm.isAdmin,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.agree()
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Accept")]
                                                  )
                                                : _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "entity-button-text",
                                                      attrs: {
                                                        block: "",
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.markExempt()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Send reminder to admin"
                                                      ),
                                                    ]
                                                  ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }