import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { IProductVersion } from '@/shared/model/product-version.model';

const baseApiUrl = 'api/private/product-versions';

export default class ProductVersionService {
  public find(id: number): Promise<IProductVersion> {
    return new Promise<IProductVersion>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public create(entity: IProductVersion): Promise<IProductVersion> {
    return new Promise<IProductVersion>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public update(entity: IProductVersion): Promise<IProductVersion> {
    return new Promise<IProductVersion>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public elbAuth(url: string, token: string): Promise<any> {
    return new Promise<IProductVersion>(() => {
      axios
        .post(`${url}`, {accessToken: token})
        .then()
        .catch();
    });
  }

  public findbyPlanId(id: number): Promise<IProductVersion[]> {
    return new Promise<IProductVersion[]>((resolve, reject) => {
      axios
        .get(`api/private/plans/${id}/product-versions/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public findbyPlanIdAndName(id: number, name: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`api/private/plans/${id}/product-versions/${name}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public sendContactUs(id: number) {
    return new Promise<any> ((resolve, reject) => {
      axios
      .post(`api/private/product-versions/${id}/contact`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  public findProjectBySearch(id: number, searchTerm: string): Promise<IProductVersion[]> {
    return new Promise<IProductVersion[]>((resolve, reject) => {
      axios
        .get(`api/private/global-search/product?searchText=${searchTerm}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
