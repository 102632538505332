import { Authority } from '@/shared/security/authority';

const Register = () => import('@/account/register/register.vue');
const RegisterClean = () => import('@/account/register/register-clean.vue');
const RegisterBuy = () => import('@/account/register/register-buy.vue');
const Activate = () => import('@/account/activate/activate.vue');
const AccountPlan = () => import('@/account/plan/plan.vue');
const Signin = () => import('@/account/signin/signin.vue');
const ResetPasswordInit = () => import('@/account/reset-password/init/reset-password-init.vue');
const ResetPasswordFinish = () => import('@/account/reset-password/finish/reset-password-finish.vue');
// const ChangePassword = () => import('@/account/change-password/change-password.vue');
const Profile = () => import('@/account/profile/profile.vue');
const Checkout = () => import('@/account/upgrade/checkout.vue');
const PlanUpgrade = () => import('@/account/plan/plan-upgrade.vue');
const SerialActivate = () => import('@/account/serial-activate/serial-activate.vue');
const OrgActivate = () => import('@/account/org-activate/org-activate.vue');
const Expired = () => import('@/account/upgrade/expired.vue');
const Exception = () => import('@/account/upgrade/exception.vue');
const SSOLogin = () => import('@/account/sso/login.vue');
const SSOSAMLCallback = () => import('@/account/sso/saml-callback.vue');
const Pay = () => import('@/account/upgrade/payment-link-checkout.vue');

export default [
  {
    path: '/register',
    name: 'Register',
    meta: { layout: 'no-navbar' },
    component: Register,
  },
  {
    path: '/register-clean',
    name: 'RegisterClean',
    meta: { layout: 'blank' },
    component: RegisterClean,
  },
  {
    path: '/register-buy',
    name: 'RegisterBuy',
    meta: { layout: 'no-navbar' },
    component: RegisterBuy,
  },
  {
    path: '/account/activate',
    name: 'Activate',
    meta: { layout: 'no-navbar' },
    component: Activate,
  },
  {
    path: '/account/plans',
    name: 'AccountPlan',
    meta: { layout: 'no-navbar' },
    component: AccountPlan,
  },
  {
    path: '/sso/login',
    redirect: '/'
  },
  {
    path: '/',
    name: 'Signin',
    meta: { layout: 'blank' },
    component: Signin,
  },
  {
    path: '/sso',
    name: 'SSOLogin',
    meta: { layout: 'blank' },
    component: SSOLogin,
  },
  {
    path: '/sso/saml-callback',
    name: 'SSOSAMLCallback',
    meta: { layout: 'blank' },
    component: SSOSAMLCallback,
  },
  {
    path: '/account/serial/activate',
    name: 'SerialActivate',
    meta: { layout: 'no-navbar' },
    component: SerialActivate,
  },
  {
    path: '/account/organization/activate',
    name: 'OrgActivate',
    meta: { layout: 'no-navbar' },
    component: OrgActivate,
  },
  {
    path: '/account/reset/request',
    name: 'ResetPasswordInit',
    meta: { layout: 'blank' },
    component: ResetPasswordInit,
  },
  {
    path: '/account/reset/finish',
    name: 'ResetPasswordFinish',
    meta: { layout: 'blank' },
    component: ResetPasswordFinish,
  },
  // {
  //   path: '/account/password',
  //   name: 'ChangePassword',
  //   component: ChangePassword,
  //   meta: { authorities: [Authority.USER] },
  // },
  {
    path: '/account/profile',
    name: 'Profile',
    component: Profile,
    meta: { authorities: [Authority.USER, Authority.ORG_ADMIN, Authority.AUTHOR, Authority.ACCOUNT_MANAGER,
                          Authority.ADMINISTRATOR, Authority.FULFILLMENT, Authority.FULFILLMENT_RO,
                          Authority.RESELLER, Authority.STUDENT] },
  },
  {
    path: '/account/checkout',
    name: 'Checkout',
    meta: { layout: 'no-navbar' },
    component: Checkout,
  },
  {
    path: '/pay',
    name: 'Pay',
    meta: { layout: 'blank' },
    component: Pay,
  },
  {
    path: '/account/expired',
    name: 'Expired',
    component: Expired,
  },
  {
    path: '/account/exception',
    name: 'Exception',
    component: Exception,
  },
  {
    path: '/account/plans-upgrade',
    name: 'PlanUpgrade',
    meta: {
      layout: 'default',
      authorities: [Authority.ORG_ADMIN, Authority.ACCOUNT_MANAGER, Authority.ADMINISTRATOR, Authority.FULFILLMENT, Authority.RESELLER]
    },
    component: PlanUpgrade,
  }
];
