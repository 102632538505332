import { Module } from 'vuex';

export const accountStore: Module<any, any> = {
  state: {
    logon: false,
    userIdentity: null,
    authenticated: false,
    ribbonOnProfiles: '',
    activeProfiles: '',
    upgrading: false,
    subscriptionExpired: false,
    reAuthentication: false,
    showLegal: false,
    legalContentList: [],
  },
  getters: {
    logon: (state) => state.logon,
    account: (state) => state.userIdentity,
    authenticated: (state) => state.authenticated,
    activeProfiles: (state) => state.activeProfiles,
    ribbonOnProfiles: (state) => state.ribbonOnProfiles,
    subscriptionExpired: (state) => state.subscriptionExpired,
    reAuthentication: (state) => state.reAuthentication,
    showLegal: (state) => state.showLegal,
    legalContentList: (state) => state.legalContentList,
  },
  mutations: {
    authenticate(state) {
      state.logon = true;
    },
    authenticated(state, identity) {
      state.userIdentity = identity;
      state.authenticated = true;
      state.logon = false;
    },
    logout(state) {
      state.userIdentity = null;
      state.authenticated = false;
      state.logon = false;
      state.showLegal = false;
    },
    setActiveProfiles(state, profile) {
      state.activeProfiles = profile;
    },
    setRibbonOnProfiles(state, ribbon) {
      state.ribbonOnProfiles = ribbon;
    },
    setUpgrading(state, upgrading) {
      state.upgrading = upgrading;
    },
    subscriptionExpired(state, expired) {
      state.subscriptionExpired = expired;
    },
    setReAuthentication(state, payload) {
      state.reAuthentication = payload;
    },
    setShowLegal(state, show) {
      state.showLegal = show;
    },
    setLegalContentList(state, list) {
      state.legalContentList = list;
    },
  },
};
