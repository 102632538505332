import axios from 'axios';
import { SERVER_API_URL, TOKEN } from '@/constants';
import { utils } from '../utils';
import Swal from 'sweetalert2';


const TIMEOUT = 1000000;
// const onRequestSuccess = config => {
//   const token = localStorage.getItem('elb-authenticationToken') || sessionStorage.getItem('elb-authenticationToken');
//   if (token) {
//     if (!config.headers) {
//       config.headers = {};
//     }
//     config.headers.Authorization = `Bearer ${token}`;
//   }
//   config.timeout = TIMEOUT;
//   config.url = `${SERVER_API_URL}${config.url}`;
//   return config;
// };
// const setupAxiosInterceptors = onUnauthenticated => {
//   const onResponseError = err => {
//     const status = err.status || err.response.status;
//     console.log(status)
//     if (status === 403 || status === 401) {
//       //onUnauthenticated();
//       localStorage.removeItem('elb-authenticationToken');
//       sessionStorage.removeItem('elb-authenticationToken');
//       onUnauthenticated();
//       // window.location.reload();
//     }
//     return Promise.reject(err);
//   };
//   if (axios.interceptors) {
//     axios.interceptors.request.use(onRequestSuccess);
//     axios.interceptors.response.use(res => res, onResponseError);
//   }
// };

// export { onRequestSuccess, setupAxiosInterceptors };
export const setHttpInterceptors = (store, router) => {
  // Request Interceptor
  const requestSuccess = (config) => {
    // Do something before request is sent
    const token = localStorage.getItem(TOKEN) || sessionStorage.getItem(TOKEN);
    if (token) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.timeout = TIMEOUT;
    config.url = `${SERVER_API_URL}${config.url}`;
    return config;

  };
  const requestError = (error) =>
    // Do something with request error
     Promise.reject(error);

  // Response Interceptor
  const responseSuccess = (response) => {
    let expired = false;
    if (response && response.headers && response.headers['x-elb-subscription-expired']) {
      expired = true;
    }

    store.commit('subscriptionExpired', expired);
    return response;
  };
  const responseError = (error) => {
    if (error.response && error.response.status) {
      if (error.response.status === 401 || error.response.status === 302) {
        if ((!error.response.data && !error.response.data.path && !error.response.data.path.includes('sso')) ||
        (!utils.hasToken())) {
          utils.cleanStorage();
          store.commit('logout');
          router.push('/');
        }
      }

      if (error.response.status === 400) {
        if (error.response.data.errorKey === 'notenoughpermission') {
          Swal.fire({
            icon: 'error',
            title: 'Permission exception!!',
            confirmButtonColor: '#000C19',
            text: 'You don\'t have permission to perform this action!!'
          })
          .then(() => {
            router.push('/product-version/apps');
          });
        }
      }

      // if (error.response.status === 426) {
      //   router.push('/account/exception?expired=1');
      // }

      // if (error.response.status === 423) {
      //   router.push('/account/exception?authorlimit=1');
      // }
  }

    return Promise.reject(error);
  };

  // Wire up the interceptors
  axios.interceptors.request.use(requestSuccess, requestError);
  axios.interceptors.response.use(responseSuccess, responseError);
};
