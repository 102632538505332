import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { IPlan } from '@/shared/model/plan.model';

const baseApiUrl = 'api/private/plans';

export default class PlanService {
  public find(id: number): Promise<IPlan> {
    return new Promise<IPlan>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public findByNickname(nickname: string): Promise<IPlan> {
    return new Promise<IPlan>((resolve, reject) => {
      axios
        .get(`api/public/plans/nickname/${nickname}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public create(entity: IPlan): Promise<IPlan> {
    return new Promise<IPlan>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public update(entity: IPlan): Promise<IPlan> {
    return new Promise<IPlan>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
