import axios from 'axios';

export default class RegisterService {
  public processRegistration(account: any, token): Promise<any> {
    const headers = {
      response: token,
    };
    return new Promise<any>((resolve, reject) => {
      axios
        .post('api/public/register', account, {
          headers,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

    // return api.post('api/register', account);
  }

  public processRegistrationBuy(account: any, token): Promise<any> {
    const headers = {
      response: token,
    };
    return new Promise<any>((resolve, reject) => {
      axios
        .post('api/public/register-buy', account, {
          headers,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

    // return api.post('api/register', account);
  }

  public isEmailUnique(email: string): Promise<any> {
    const headers = {
      headers: { 'Content-Length': 0, 'Content-Type': 'text/plain' },
    };
    return new Promise<any>((resolve, reject) => {
      axios
        .post('api/public/organizations/exists', email, headers)
        .then((response) => {
          resolve(response.data === false);
        })
        .catch(() => {
          reject();
        });
    });
  }
}
