var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            app: "",
            clipped: "",
            permanent: _vm.sidebarMenu,
            "mini-variant": _vm.mini,
            color: "#F3F4F6",
          },
          on: {
            "update:miniVariant": function ($event) {
              _vm.mini = $event
            },
            "update:mini-variant": function ($event) {
              _vm.mini = $event
            },
          },
          model: {
            value: _vm.sidebarMenu,
            callback: function ($$v) {
              _vm.sidebarMenu = $$v
            },
            expression: "sidebarMenu",
          },
        },
        [
          _c("v-divider", { staticClass: "mtn-2", attrs: { color: "white" } }),
          _vm._v(" "),
          _c(
            "v-list",
            { attrs: { nav: "", dense: "", color: "#F3F4F6" } },
            [
              _vm._l(_vm.menuItems, function (item, index) {
                return [
                  item.items
                    ? _c(
                        "v-list-group",
                        {
                          key: index,
                          class: {
                            "bg-grey": true,
                            "bg-grey-300": item.routeName === _vm.$route.name,
                          },
                          attrs: {
                            color: "#1F2937",
                            "no-action": "",
                            ripple: "",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function () {
                                  return [
                                    _c(
                                      "v-list-item-icon",
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: "#1F2937" } },
                                          [_vm._v(_vm._s(item.icon))]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", {
                                          staticClass: "list-txt",
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t(item.title)
                                            ),
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _vm._v(" "),
                          _vm._l(item.items, function (subItem) {
                            return _c(
                              "v-list-item",
                              {
                                key: subItem.title,
                                class: {
                                  "bg-grey": true,
                                  "bg-grey-300":
                                    subItem.routeName === _vm.$route.name,
                                },
                                staticStyle: { "padding-left": "24px" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$router.push({
                                      name: subItem.routeName,
                                    })
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-list-item-icon",
                                  [
                                    _c(
                                      "v-icon",
                                      { attrs: { color: "#1F2937" } },
                                      [_vm._v(_vm._s(subItem.icon))]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "v-list-item-content",
                                  [
                                    _c("v-list-item-title", {
                                      staticClass: "list-txt",
                                      domProps: {
                                        textContent: _vm._s(
                                          _vm.$t(subItem.title)
                                        ),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      )
                    : _c(
                        "v-list-item-group",
                        {
                          key: index,
                          class: {
                            "bg-grey": true,
                            "bg-grey-300": item.routeName === _vm.$route.name,
                          },
                          attrs: { color: "#E5E7EB" },
                          model: {
                            value: _vm.selected,
                            callback: function ($$v) {
                              _vm.selected = $$v
                            },
                            expression: "selected",
                          },
                        },
                        [
                          _c(
                            "v-list-item",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.$router.push({
                                    name: item.routeName,
                                  })
                                },
                              },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { color: "#1F2937" } },
                                    [_vm._v(_vm._s(item.icon))]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-list-item-content",
                                [
                                  _c("v-list-item-title", {
                                    staticClass: "list-txt",
                                    domProps: {
                                      textContent: _vm._s(_vm.$t(item.title)),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ]
              }),
              _vm._v(" "),
              !_vm.authenticated
                ? _c(
                    "v-list-item",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.openLogin()
                        },
                      },
                    },
                    [
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-icon", { attrs: { color: "secondary" } }, [
                            _vm._v("exit_to_app"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("v-list-item-title", [
                        _c(
                          "span",
                          {
                            staticClass: "list-txt",
                            domProps: {
                              textContent: _vm._s(
                                _vm.$t("global.menu.account.login")
                              ),
                            },
                          },
                          [_vm._v("Sign in")]
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c("v-divider", { attrs: { color: "#F3F4F6" } }),
          _vm._v(" "),
          _vm.resourceItems.length > 0
            ? _c(
                "v-list",
                { attrs: { dense: "", color: "#F3F4F6" } },
                [
                  _c(
                    "v-list-item-group",
                    { attrs: { color: "#F3F4F6" } },
                    _vm._l(_vm.resourceItems, function (item, i) {
                      return _c(
                        "v-list-item",
                        {
                          key: i,
                          attrs: { target: "_blank" },
                          on: {
                            click: function ($event) {
                              return _vm.launchNewTab(item.link)
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                staticClass: "list-txt",
                                domProps: { textContent: _vm._s(item.name) },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-app-bar",
        {
          attrs: {
            app: "",
            "clipped-left": "",
            color: "primary",
            dark: "",
            elevation: "0",
            height: "60",
          },
        },
        [
          _c("v-app-bar-nav-icon", {
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.toggleMini = !_vm.toggleMini
              },
            },
          }),
          _vm._v("\n       \n    "),
          _c("v-img", {
            staticClass: "shrink hidden-sm-and-down left-logo mb-2 mt-2",
            attrs: {
              alt: "ELB Learning Logo",
              "max-width": "44",
              "max-height": "32",
              src: "/content/images/email/elb_logo_white.png",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                _vm.sidebarMenu = !_vm.sidebarMenu
              },
            },
          }),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("img", {
            attrs: {
              alt: "Studio Logo",
              src: "/content/images/TheStudio_Logo_Light.svg",
              height: "30",
              width: "150",
            },
          }),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("toolbar-user-menu", {
            attrs: { userAccount: _vm.userAccount },
            on: {
              logoutClicked: function ($event) {
                return _vm.logout()
              },
              accountSettingsClicked: _vm.accountSettings,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }