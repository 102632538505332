import VueI18n from 'vue-i18n';
import { Store } from 'vuex';
import { BUILD_TIMESTAMP } from '@/constants';

export default class TranslationService {
  private store: Store<{}>;
  private i18n: VueI18n;

  constructor(store: Store<{}>, i18n: VueI18n) {
    this.store = store;
    this.i18n = i18n;
  }

  public refreshTranslation(newLanguage: string) {
    let currentLanguage = this.store.getters.currentLanguage;
    currentLanguage = newLanguage ? newLanguage : 'en';
    // const absolute = window.location.protocol + '//' + window.location.hostname + (window.location.port !== '' ? `:${window.location.port}` : '')
    if (this.i18n && !this.i18n.messages[currentLanguage]) {
      this.i18n.setLocaleMessage(currentLanguage, {});
      fetch(`/i18n/${currentLanguage}.json?buildTimestamp=${BUILD_TIMESTAMP}`)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          this.i18n.setLocaleMessage(currentLanguage, data);
          this.i18n.locale = currentLanguage;
          this.store.commit('currentLanguage', currentLanguage);
        }
      });
    } else if (this.i18n) {
      this.i18n.locale = currentLanguage;
      this.store.commit('currentLanguage', currentLanguage);
    }
  }
}
