import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { IRegion } from '@/shared/model/region.model';

const baseApiUrl = 'api/';

export default class RegionService {
  public find(id: number): Promise<IRegion> {
    return new Promise<IRegion>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}public/regions/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `public/regions?${buildPaginationQueryOpts(paginationQuery)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}private/regions/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public create(entity: any): Promise<IRegion> {
    return new Promise<IRegion>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}private/regions`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public update(entity: any): Promise<IRegion> {
    return new Promise<IRegion>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}private/regions`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
