import { TOKEN } from '@/constants';
import { utils } from '@/shared/utils';


export default class LoginService {

  public logout() {
    return new Promise((resolve) => {
      utils.cleanStorage();
      resolve(true);
    });
  }

  public getAccessToken() {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem(TOKEN);
      if (token) {
        resolve(token);
      } else {
        reject(-1);
      }
    });
  }


}
