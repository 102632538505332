<template>
  <div>
    <ribbon></ribbon>
    <jhi-navbar></jhi-navbar>
    <v-row justify="center">
      <v-col cols="12">
        <v-main>
          <v-container class="px-4 py-0 fill-height" fluid>
            <v-row class="fill-height">
              <v-col>
                <transition name="fade">
                  <router-view :key="$route.fullPath" />
                </transition>
              </v-col>
              
            </v-row>
            
          </v-container>
        </v-main>
      </v-col>
    </v-row>
    <jhi-footer></jhi-footer>
  </div>
</template>
<script>
import Ribbon from '@/core/ribbon/ribbon.vue';
import JhiFooter from '@/core/jhi-footer/jhi-footer.vue';
import JhiNavbar from '@/core/jhi-navbar/jhi-navbar.vue';
export default {
  components: {
    ribbon: Ribbon,
    'jhi-navbar': JhiNavbar,

    'jhi-footer': JhiFooter,
  },
  data() {
    return {
      expired: false
    }
  },
  created() {
    this.expired = this.$store.getters.subscriptionExpired || false
  }
};
</script>
