import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';

export default class BatchService {

  public userImport(formData: any): Promise<any> {
    const options = {
      headers: {'Content-Type': 'multipart/form-data'}
    };
    return axios.post('api/private/batch/users-import', formData, options);
  }

  public organizationUsersImport(formData: any, orgId: number): Promise<any> {
    const options = {
      headers: {'Content-Type': 'multipart/form-data'}
    };
    return axios.post(`api/private/batch/organizations/${orgId}/users-import`, formData, options);
  }

  public find(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`api/private/failed-user-imports/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public count(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios.get('api/private/failed-user-imports' + `?${buildPaginationQueryOpts(paginationQuery)}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get('api/private/failed-user-imports' + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public deleteAll(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete('api/private/failed-user-imports/all')
        .then(() => {
          resolve(true);
        })
        .catch(() => {
          reject(false);
        });
    });
  }

  public retrieveUserTemp(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get('api/private/user-temps' + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
