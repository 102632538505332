import UpgradeService from '@/account/upgrade/upgrade.service';
import { Store } from 'vuex';

export default class StripePlanService {
  private store: Store<{}>;
  private upgradeService: any;

  constructor(store: Store<{}>, upgradeService: UpgradeService) {
    this.store = store;
    this.upgradeService = upgradeService;
  }

  public async setAllPlans() {
    if (!this.store.getters.allPlans) {
      const response = await this.upgradeService.getAllPlans();
      if (response) {
        this.store.commit('allPlans', JSON.stringify(response));
      }
    }
  }

  public async getAllPlans() {
    if (!this.store.getters.allPlans) {
      await this.setAllPlans();
    }

    return this.store.getters.allPlans;

  }


}
