import { Store } from 'vuex';


const ipGeolocationKey = 'e58545211980446e97c9638e740f3545';

export default class LocationService {
  private store: Store<{}>;

  constructor(store: Store<{}>) {
    this.store = store;
  }

  public setLocation() {
    if (!this.store.getters.currentLocation) {
      fetch('https://api.ipgeolocation.io/ipgeo?apiKey=' + ipGeolocationKey)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          this.store.commit('currentLocation', JSON.stringify(data, null, 2));
        }
      });
    }
  }


}
