import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { ResourceInterface } from '@/shared/component/resource.interface';

const baseApiUrl = 'api/private/web-hook-conversations';

export default class WebHookConversationService implements ResourceInterface {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  find(id: number): Promise<any> {
    throw new Error('Method not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  delete(id: number): Promise<any> {
    throw new Error('Method not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  create(entity: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  update(entity: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}?${buildPaginationQueryOpts(paginationQuery)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public resend(conversation: any) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/resend`, conversation)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
