import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { ResourceInterface } from '@/shared/component/resource.interface';

const baseApiUrl = 'api/private/user-temps';

export default class TempUserService implements ResourceInterface {
  public find(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public findByEmail(email: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/search/${encodeURIComponent(email)}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }


  public count(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios.get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public create(entity: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public async batchUpdate(arr: any): Promise<any> {
    await axios.put(`${baseApiUrl}/import`, { 'userTempList': arr});
  }

  public mergeUsers(arr: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios.post(`${baseApiUrl}/merge`, { 'productUserList': arr})
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
    });

  }

  public emailInUse(email: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
      .post('api/public/users/exists', email,{ headers: {'Content-Type': 'text/plain' } } )
      .then((response) => {
        resolve( response.data);
      }).catch(() => {
        reject();
      });
    });
  }

  public organizationinUse(name: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
      .post('api/public/organizations/exists', name,{ headers: {'Content-Type': 'text/plain' } } )
      .then((response) => {
        resolve( response.data );
      }).catch(() => {
        reject();
      });
    });
  }

  public update(entity: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
