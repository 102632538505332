import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { IEndPoint } from '@/shared/model/end-point.model';
import { ResourceInterface } from '@/shared/component/resource.interface';

const baseApiUrl = 'api/private/endpoints';

export default class EndpointService implements ResourceInterface {
  public find(id: number): Promise<IEndPoint> {
    return new Promise<IEndPoint>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public findByUserId(id: number): Promise<IEndPoint> {
    const paginationQuery = {
      page: 0,
      size: 1,
      sort: ['id,asc'],
      predicate: [{ name: 'userId.equals', value: id }],
    };

    return new Promise<any>((resolve, reject) => {
      this.retrieve(paginationQuery)
        .then((res) => {
          resolve(res.data[0]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public count(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public create(entity: IEndPoint): Promise<IEndPoint> {
    return new Promise<IEndPoint>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            reject(err.response.data);
          } else {
            reject(err);
          }
        });
    });
  }

  public update(entity: IEndPoint): Promise<IEndPoint> {
    return new Promise<IEndPoint>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
