import Component from 'vue-class-component';
import { Inject } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import AccountService from '../account.service';
import AgreementService from './agreement.service';
import AcceptanceMixin from '@/shared/acceptance/acceptance.mixin';
import { Organization } from '@/shared/model/organization.model';

@Component({
  mixins: [AcceptanceMixin],
  components: {},
})
export default class Agrement extends mixins(AcceptanceMixin) {

  @Inject('accountService')
  private accountService: () => AccountService;

  @Inject('agreementService')
  private agreementService: () => AgreementService;

  // public showLegal = false;

  created(): void {
    console.log('created');
    if (this.$route.path === '/') {
      this.showLegal = false;
    }


    this.getLegalFromStore();
    this.$store.watch(
      () => this.$store.getters.showLegal,
      (val) => {
       // something changed do something
       this.showLegal = val;
      },
      {
        deep: true
      }
    );
    // this.$store.watch(
    //   () => this.$store.getters.showLegal,
    //   () => {
    //     confirm('showLegal changed');
    //     this.showLegal = this.$store.getters.showLegal;
    //   }
    // );

    this.getLatestFromService().then(() => {
      this.getLegalContentFromStore();
    });

    this.markExempt();

  }


  public needsToAcceptLegal() {
    return this.$store.getters.showLegal;
  }


  public disagree() {
    this.$store.commit('setShowLegal', false);
    this.$store.commit('logon', false);
    this.$store.commit('logout');
    this.$router.push({ path: '/' });
  }

  public agree() {
    this.agreementService()
    .addAcceptance(
      this.accountService().getUserOrganizationId(),
      this.accountService().getUserId()
    )
    .then((res) => {
      console.log(res);
      this.$store.commit('setShowLegal', false);
    });
  }

  public sendReminderToAdmins() {
    console.log('sendReminderToAdmins');
    this.agreementService()
    .sendReminderToAdmins(
      this.accountService().getUserOrganizationId(),
      this.accountService().getUserId()
    );
  }

  public get oganizationName() {
    return this.accountService().getUserOrganizationName();
  }

  public markExempt() {
    console.log('sendAdminRequest');
    const organization = new Organization();
    organization.id = this.accountService().getUserOrganizationId();
    organization.name = this.accountService().getUserOrganizationName();
    this.agreementService()
    .exempt(
      organization,
      false
    )
    .then((res) => {
      console.log(res);
    });
  }

  public get isAdmin() {
    return this.accountService().isAccountManager() || this.accountService().isOrgAdmin() || this.accountService().isAdmin();
  }

  public get notAdminAndReminderSent() {
    if (this.isAdmin) {
      return false;
    }

    return false;
  }

  public showAlert(msg) {
    this.$swal
      .fire({
        icon: 'error',
        title: 'Sign In Error',
        confirmButtonColor: '#000C19',
        text: msg,
        confirmButtonText: 'Switch User',
      })
      .then((result) => {
        console.log(result);
      });
  }
}
