import axios from 'axios';

export default class ResetPasswordService {
  public requestReset(email: string): Promise<boolean> {
    return new Promise((resolve) => {
      axios
      .post('api/public/account/reset-password/init', email, {
        headers: {
          'content-type': 'text/plain',
        },
      })
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        resolve(false);
      });
    });
  }

  public finishReset(keyAndPassword: any): Promise<boolean> {
    return new Promise((resolve) => {
    axios
      .post('api/public/account/reset-password/finish', keyAndPassword)
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        resolve(false);
      });
    });
  }

  public getPasswordPolicies(key: string): Promise<any> {
    return new Promise((resolve, reject) => {
      axios
      .get(`api/public/account/users/${key}/settings/password-policies`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  public getUserInfo(key: string): Promise<any> {
    return new Promise( (resolve, reject) => {
      axios
      .get(`api/public/account/users/${key}`)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
}
