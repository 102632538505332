import axios from 'axios';
import { IAISuggestion } from '@/shared/model/ai-suggestion.model';

const baseApiUrl = 'api/private/open-ai';

export default class HomeService {

  public retrieveMessages(threadId: string): Promise<any> {

    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/get-message/${threadId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public retrieveRun(threadId: string, runId: string): Promise<any> {

    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/get-run-status/${threadId}/${runId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public createThread(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/create-thread`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public createMessage(entity: IAISuggestion, id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/create-message/${id}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public runThread(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/run/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
