import { Component, Prop, Vue } from 'vue-property-decorator';
import { getAuthorityToName } from '../security/authority';

@Component({
  components: {
  },
})
export default class ToolbarNotificationMenu extends Vue {
  @Prop({type: Object, default: null})
  userAccount: any;

  public mounted(): void {

  }

  public logout() {

  }

  public get currentUser() {
    if (this.userAccount && this.userAccount.firstName) {
      return this.userAccount.firstName + ' ' + this.userAccount.lastName;
    }

    return null;
  }

  public getRoleName(role) {
    return getAuthorityToName(role);
  }

  public accountSettingsClicked() {
    this.$emit('accountSettingsClicked');
  }

  public logoutClicked() {
    this.$emit('logoutClicked');
  }
}
