import { Module } from 'vuex';

export const locationStore: Module<any, any> = {
  state: {
    currentLocation: localStorage.getItem('currentLocation') || null,
  },
  getters: {
    currentLocation(state) {
      if (state.currentLocation) {
        return JSON.parse(state.currentLocation);
      }

      return null;
    }
  },
  mutations: {
    currentLocation(state, newLocation) {
      state.currentLocation = newLocation;
      localStorage.setItem('currentLocation', newLocation);
    },
  },
};
