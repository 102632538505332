import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { ResourceInterface } from '@/shared/component/resource.interface';
import { INotificationRecipient } from '@/shared/model/notification-recipient.model';

const baseApiUrl = 'api/private/notification-recipients';

export default class NotificationRecipientService implements ResourceInterface {
    public find(id: number): Promise<INotificationRecipient> {
        return new Promise<INotificationRecipient>((resolve, reject) => {
            axios
            .get(`${baseApiUrl}/${id}`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
        });
    }

    public retrieve(paginationQuery?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
          axios
            .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
    }

    public delete(id: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
          axios
            .delete(`${baseApiUrl}/${id}`)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
    }

    public create(entity: INotificationRecipient): Promise<INotificationRecipient> {
        return new Promise<INotificationRecipient>((resolve, reject) => {
          axios
            .post(`${baseApiUrl}`, entity)
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(err);
            });
        });
    }

    public update(entity: INotificationRecipient): Promise<INotificationRecipient> {
        return new Promise<INotificationRecipient>((resolve, reject) => {
            axios
            .put(`${baseApiUrl}`, entity)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
        });
    }


}
