import axios from 'axios';import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { IElbSubscription } from '@/shared/model/elb-subscription.model';
import { ResourceInterface } from '@/shared/component/resource.interface';
import { IScheduleDowngrade } from '@/shared/model/schedule-downgrade.model';

const baseApiUrl = 'api/private/subscriptions';

export default class ElbSubscriptionService implements ResourceInterface {
  public find(id: number): Promise<IElbSubscription> {
    return new Promise<IElbSubscription>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public async retriveAsync(paginationQuery?: any) {
    const request = axios.get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`);
    const response = await request;
    return response.data;
  }

  public export(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/export?${buildPaginationQueryOpts(paginationQuery)}`,  {responseType: 'blob'})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public retriveUniquePlans(resellerId): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
      .get(baseApiUrl + `/plans/${resellerId}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public cancelDowngrade(id: number): Promise<IElbSubscription> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/schedule-downgrade/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public createScheduleDowngrade(entity: IScheduleDowngrade): Promise<IScheduleDowngrade> {
    return new Promise<IScheduleDowngrade>((resolve, reject) => {
      axios
        .post('api/private/schedule-downgrades', entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }


  public create(entity: IElbSubscription): Promise<IElbSubscription> {
    return new Promise<IElbSubscription>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public update(entity: IElbSubscription): Promise<IElbSubscription> {
    return new Promise<IElbSubscription>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }


  public retrieveError(stripeId?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`api/private/stripe/subscription/${stripeId}/error`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public getSubscriptionHistory(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
      .get(`api/private/subscriptions/${id}/subscription-histories`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  public convertToInvoiced(id: number): Promise<IElbSubscription> {
    return new Promise<IElbSubscription>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${id}/invoiced`, id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public syncToStripe(id: number): Promise<IElbSubscription> {
    return new Promise<IElbSubscription>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${id}/sync`, id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public createUpcomiingDeal(id: number): Promise<IElbSubscription> {
    return new Promise<IElbSubscription>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${id}/deal`, id)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
