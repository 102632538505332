import axios from 'axios';

const baseApiUrl = 'api/';

export default class UpgradeService {
  public async getStripePlanEstimates(userDto: any) {
    userDto.prorate = true;
    userDto.endOfTerm = false;
    const prorateRequest = axios.post(`${baseApiUrl}public/stripe/estimate`, userDto);
    const prorateResponse = await prorateRequest;
    return prorateResponse.data;
  }

  public async getPaymentLinkEstimate(userDto: any) {
    userDto.prorate = true;
    userDto.endOfTerm = false;
    const prorateRequest = axios.post(`${baseApiUrl}public/stripe/payment-links/estimate`, userDto);
    const prorateResponse = await prorateRequest;
    return prorateResponse.data;
  }

  public async stripeReinstateSubscription(subscriptionId) {
    const changeRequest = axios.put(`${baseApiUrl}private/stripe/subscription/reinstate/${subscriptionId}`);
    const changeResponse = await changeRequest;
    return changeResponse.data;
  }

  public async tripeCancelSubscription() {
    const changeRequest = axios.post(`${baseApiUrl}stripe/subscription/cancel`, {});
    const changeResponse = await changeRequest;
    return changeResponse.data;
  }

  public async stripeCancelDowngrade(subscriptionId): Promise<any> {
    const changeRequest = axios.delete(`${baseApiUrl}private/stripe/subscription/downgrade/${subscriptionId}`);
    const changeResponse = await changeRequest;
    return changeResponse.data;
  }

  public async getStripeCoupon(coupon?: any) {
    const changeRequest = axios.get(`${baseApiUrl}public/stripe/coupon/${coupon}`);
    const changeResponse = await changeRequest;
    return changeResponse.data;
  }

  public async stripeCancelSubscription(userDto) {
    const changeRequest = axios.post(`${baseApiUrl}private/stripe/subscription/cancel`, userDto);
    const changeResponse = await changeRequest;
    return changeResponse.data;
  }

  public async stripeCheckout(userDto) {
    const checkoutRequest = axios.post(`${baseApiUrl}public/stripe/checkout`, userDto);
    const checkoutResponse = await checkoutRequest;
    return checkoutResponse.data;
  }

  public async stripePaymentLinkCheckout(userDto) {
    const checkoutRequest = axios.post(`${baseApiUrl}public/stripe/payment-links/checkout`, userDto);
    const checkoutResponse = await checkoutRequest;
    return checkoutResponse.data;
  }


  public async stripeResellerCheckout(userDto) {
    const checkoutRequest = axios.post(`${baseApiUrl}private/stripe/resellers/checkout`, userDto);
    const checkoutResponse = await checkoutRequest;
    return checkoutResponse;
  }


  public async stripeUpdateBilling(data) {
    const changeRequest = axios.put(`${baseApiUrl}private/stripe/customers`, data);
    const changeResponse = await changeRequest;
    return changeResponse.data;
  }

  public async stripeCreateCustomer(data) {
    const changeRequest = axios.post(`${baseApiUrl}private/stripe/customers`, data);
    const changeResponse = await changeRequest;
    return changeResponse.data;
  }

  public async stripePayInvoice(data) {
    const payInvoice = axios.post(`${baseApiUrl}private/stripe/payinvoice`, data);
    const response = await payInvoice;
    return response.data;

  }

  public async stripeChangePlan(userDto) {
    // if (userDto.numberOfAuthors == null) {
    //   let currSub = await context.dispatch('stripeCurrSubscription')
    //   userDto.numberOfAuthors = currSub.plan_quantity
    // }
    const changeRequest = axios.put(`${baseApiUrl}private/stripe/checkout`, userDto);
    const changeResponse = await changeRequest;
    return changeResponse.data;
  }

  public async stripEenterpriseCheckout(userDto) {
    const changeRequest = axios.post(`${baseApiUrl}private/stripe/enterprise/checkout`, userDto);
    const changeResponse = await changeRequest;
    return changeResponse.data;
  }


  public async getStripePublishableKey(defaultPublishableKey) {
    const req = axios.get(`${baseApiUrl}public/stripe/publishable`);
    let retPubKey = defaultPublishableKey;
    try {
      const resp = await req;
      if (resp.data && resp.data.length) {
retPubKey = resp.data;
}
    } catch (e) {
      console.log(e);
    }
    return retPubKey;
  }

  async getStripeCustomer() {
    const customerRequest = axios.get(`${baseApiUrl}private/stripe/customer`);
    const customerResponse = await customerRequest;
    return customerResponse.data;
  }

  async getStripeCustomerCards() {
    const customerRequest = axios.get(`${baseApiUrl}private/stripe/customer/cards`);
    const customerResponse = await customerRequest;
    return customerResponse.data;
  }

  public async getCurrentUserProfile(userId) {
    const userRequest = axios.get(`${baseApiUrl}private/users/${userId}`);
    const userResponse = await userRequest;
    return userResponse.data.data;
  }

  async getAvailablePlans(userDto) {
    const request = axios.post(`${baseApiUrl}public/stripe/plans`, userDto);
    const plans = await request;
    return plans.data;
  }

  async getSubscriptionById(id: string) {
    const request = axios.get(`${baseApiUrl}public/stripe/subscriptions/${id}`);
    const subscription = await request;
    return subscription.data;
  }

  async getPlanById(id: string) {
    const request = axios.get(`${baseApiUrl}public/stripe/plans/${id}`);
    const plan = await request;
    return plan.data;
  }

  async getStripePriceById(id: string) {
    const request = axios.get(`${baseApiUrl}private/stripe/prices/${id}`);
    const plan = await request;
    return plan.data;
  }

  async getAllPlans() {
    const request = axios.get(`${baseApiUrl}public/stripe/plans`);
    const plans = await request;
    return plans.data;
  }

  public async getStripePlans() {
    const request = axios.get(`${baseApiUrl}private/stripe/prices/one-time`);
    const pricesRequest = await request;
    return pricesRequest.data.data;
  }

}
