import Vue from 'vue';
import Component from 'vue-class-component';
import Ribbon from '@/core/ribbon/ribbon.vue';
import JhiFooter from '@/core/jhi-footer/jhi-footer.vue';
import Reauthenticate from './account/signin/reauthenticate.vue';
import Agreement from './account/agreement/agreement.vue';

const default_layout = 'default';

@Component({
  components: {
    ribbon: Ribbon,
    reauthenticate: Reauthenticate,
    agreement: Agreement,
    'jhi-footer': JhiFooter,
  },
  computed: {
    reAuthentication() {
      return this.$store.getters.reAuthentication;
    },
    legalAgreement() {
      return this.$store.getters.showLegal;
    },
    layout() {
      return (this.$route.meta.layout || default_layout) + '-layout';
    },
    background() {
      if (this.layout === 'default-layout' || this.layout === 'no-navbar-layout') {
        return 'background-pages';
      } else {
        return 'background-default';
      }
    }
  },
})
export default class App extends Vue {
  created(): void {
    // nothing defined here (when this.$route.path is other than "/")
  }

  updated(): void {
    // something defined here whatever the this.$route.path
  }
}
