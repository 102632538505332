export interface IUserActivity {
  id?: number;
  event?: string;
  created?: Date;
  ip?: string;
  latitude?: string;
  longitude?: string;
  applicationUserId?: number;
  verb?: string;
  countryCode2?: string;
  countryCode3?: string;
  country?: string;
  flag?: string;
}

export class UserActivity implements IUserActivity {
  constructor(
    public id?: number,
    public event?: string,
    public created?: Date,
    public ip?: string,
    public latitude?: string,
    public longitude?: string,
    public applicationUserId?: number,
    public verb?: string,
    public countryCode2?: string,
    public countryCode3?: string,
    public country?: string,
    public flag?: string
  ) {}
}
