<template>
  <div>
    <ribbon></ribbon>
    <v-app-bar app color="white"  elevation="1" height="100">
      <v-row  justify="center" >
        <img class="logo-size" src="/content/images/email/elb_logo_black.png">
      </v-row>
    </v-app-bar>
    <v-row justify="center">
      <v-col cols="12">
        <v-main>
          <v-container class="px-4 py-0 fill-height" fluid>
            <v-row class="fill-height">
              <v-col>
                <transition name="fade">
                  <router-view :key="$route.fullPath" />
                </transition>
              </v-col>
              
            </v-row>
            
          </v-container>
        </v-main>
      </v-col>
    </v-row>
    <jhi-footer></jhi-footer>
  </div>
</template>
<script>
import JhiFooter from '@/core/jhi-footer/jhi-footer.vue';
import Ribbon from '@/core/ribbon/ribbon.vue';
export default {
  components: {
    ribbon: Ribbon,
    'jhi-footer': JhiFooter,
  }
}
</script>
