var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticStyle: {
        position: "absolute",
        "z-index": "9",
        "backdrop-filter": "blur(5px)",
      },
      attrs: { "fill-height": "", fluid: "" },
    },
    [
      _c(
        "v-row",
        { attrs: { align: "center", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { align: "center", justify: "center" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "login-box",
                  attrs: { flat: "", "max-width": "500px" },
                },
                [
                  _vm.authenticationError
                    ? _c(
                        "v-alert",
                        { attrs: { type: "error", dismissible: "" } },
                        [
                          _c(
                            "span",
                            {
                              domProps: {
                                textContent: _vm._s(
                                  _vm.$t("login.messages.error.authentication")
                                ),
                              },
                            },
                            [
                              _c("strong", [_vm._v("Failed to sign in!")]),
                              _vm._v(
                                " Please check your credentials and try again."
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card",
                {
                  staticClass: "mt-16",
                  attrs: { outlined: "", "max-width": "460px" },
                },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "mx-auto card-profile rounded-circle",
                      attrs: { height: "80", "max-width": "80" },
                    },
                    [
                      _c(
                        "v-avatar",
                        {
                          staticClass: "profile rounded-circle",
                          attrs: { size: "80", tile: "" },
                        },
                        [
                          _c("v-img", {
                            attrs: { src: _vm.settingsAccount.imageUrl },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    {
                      staticClass: "mt-16",
                      attrs: { align: "center", justify: "center" },
                    },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "toastify-container",
                          attrs: { cols: "10" },
                        },
                        [
                          _c(
                            "form",
                            {
                              attrs: { role: "form" },
                              on: {
                                submit: function ($event) {
                                  $event.preventDefault()
                                  return _vm.doLogin()
                                },
                              },
                            },
                            [
                              _c("v-row"),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      id: "password",
                                      name: "password",
                                      "append-icon": _vm.hidden
                                        ? "visibility"
                                        : "visibility_off",
                                      type: _vm.hidden ? "password" : "text",
                                      label: _vm.$t("login.form.password"),
                                      "prepend-inner-icon": "mdi-lock",
                                      "hide-details": "auto",
                                      clearable: "",
                                      outlined: "",
                                      dense: "",
                                    },
                                    on: {
                                      "click:append": () =>
                                        (_vm.hidden = !_vm.hidden),
                                      focus: function ($event) {
                                        _vm.authenticationError = false
                                      },
                                    },
                                    model: {
                                      value: _vm.password,
                                      callback: function ($$v) {
                                        _vm.password = $$v
                                      },
                                      expression: "password",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                {
                                  attrs: {
                                    align: "center",
                                    justify: "space-around",
                                  },
                                },
                                [
                                  _c("v-col", { attrs: { cols: "4" } }, [
                                    _vm._v(" "),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "4" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "white--text",
                                          attrs: {
                                            color: "secondary",
                                            type: "submit",
                                            block: "",
                                            loading: _vm.loading,
                                            disabled: _vm.loading,
                                          },
                                          domProps: {
                                            textContent: _vm._s(
                                              _vm.$t("login.form.button")
                                            ),
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                      Sign in\n                    "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-col", { attrs: { cols: "4" } }, [
                                    _vm._v(" "),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-overlay",
                    { attrs: { absolute: true, value: _vm.loading } },
                    [
                      _c("v-progress-circular", {
                        attrs: { indeterminate: "", size: "60" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }