import { REFRESH_TOKEN } from '@/constants';

const TOKEN = 'elb-authenticationToken';
export default class StorageService {

  public storeToken(token: string) {
    localStorage.setItem(TOKEN, token);
    // this.setWithExpiry(TOKEN, token, ttl);
  }

  public getToken() {
    const item = localStorage.getItem(TOKEN);
    // if (item) return item.access_token //this.getWithExpiry(TOKEN);
    return item;
  }

  public getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  public deleteToken() {
    localStorage.removeItem(TOKEN);
  }

  public setWithExpiry(key: string, value: string, ttl) {
    const now = new Date();
    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value,
      expiry: now.getTime() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  }

  public getWithExpiry(key: string) {
    const itemStr = localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    // compare the expiry time of the item with the current time
    // if (now.getTime() > item.expiry) {
    //   // If the item is expired, delete the item from storage
    //   // and return null
    //   localStorage.removeItem(key)
    //   return null
    // }
    return item.value;
  }

}
