export default function buildPaginationQueryOpts(paginationQuery) {
  if (paginationQuery) {
    let sorts = '';
    for (const idx of Object.keys(paginationQuery.sort)) {
      if (sorts.length > 0) {
        sorts += '&';
      }
      sorts += 'sort=' + paginationQuery.sort[idx];
    }
    let options = '';
    if (paginationQuery.predicate) {
      paginationQuery.predicate.forEach((element) => {
        if (element['name'] !== 'sort') {
          options += element['name'] + '=' + encodeURIComponent(element['value']) + '&';
        }
      });
      if (options !== '') {
        options = options.slice(0, -1);
        return `${options}&${sorts}&page=${paginationQuery.page}&size=${paginationQuery.size}`;
      }
    }
    return `${sorts}&page=${paginationQuery.page}&size=${paginationQuery.size}`;
  }
  return '';
}
