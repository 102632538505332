import jwt_decode  from 'jwt-decode';
import { IPlan } from './model/plan.model';
import moment from 'moment';
import { REFRESH_TOKEN, TOKEN } from '@/constants';
import { IOrganization } from './model/organization.model';


const waiting = {};
const throttleList = {};

export const utils = {
  wait(milli, response) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(response == null ? true : response);
      }, milli);
    });
  },

  convertArrayOfObjectsToCSV(args: any) {
    let result,
        ctr,
        keys,
        columnDelimiter,
        lineDelimiter,
        data;

    // eslint-disable-next-line prefer-const
    data = args.data || null;
    if (data == null || !data.length) {
      return null;
    }

    keys = args.keys || null;
    if (keys == null) {
      keys = Object.keys(data[0]);
    }

    // eslint-disable-next-line prefer-const
    columnDelimiter = args.columnDelimiter || ',';
    // eslint-disable-next-line prefer-const
    lineDelimiter = args.lineDelimiter || '\n';

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function(item) {
      ctr = 0;
      keys.forEach(function(key) {
        if (ctr > 0) {
          result += columnDelimiter;
        }

        result += item[key];
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  },
  jsonTocsv(arrData: any, fileName: string) {
    const replacer = (key, value) => value === null ? '' : value; // specify how you want to handle null values here
    const header = Object.keys(arrData[0]);
    let csvContent = arrData.map((row) => header.map((fieldName) =>
    JSON.stringify(row[fieldName], replacer)).join(','));
    csvContent.unshift(header.join(','));
    csvContent = csvContent.join('\r\n');

    const data = new Blob(['\ufeff', csvContent]);
    const url = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName + '.csv');
    link.click();
  },


  csvExport(headers: any, arrData: any, fileName: string) {
    const csvContent = this.convertArrayOfObjectsToCSV({data: arrData, keys: headers });

    if (csvContent == null) {
return;
}

    fileName = fileName || 'export';

    // if (!csvContent.match(/^data:text\/csv/i)) {
    //   csvContent = 'data:text/csv;charset=utf-8,' + csvContent;
    // }

    const data = new Blob(['\ufeff', csvContent]);
    const url = URL.createObjectURL(data);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', fileName + '.csv');
    link.click();
  },

  toCSV(data: any, fileName: string) {

    fileName = fileName || 'export.csv';

    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
  },

  checkTokenValidity(token: string) {
    if (token) {
      const decodedToken = jwt_decode(token);
      if (decodedToken) {
        localStorage.setItem('exp', decodedToken['exp']);
        return decodedToken && (decodedToken['exp'] * 1000) > new Date().getTime();
      }

    }
    return false;
  },

  decodeToken(token: string) {
    if (token) {
      return jwt_decode(token);
    }
    return null;
  },

  checkTokenExpValidity(exp: any) {
    return exp && (exp * 1000) > new Date().getTime();
  },

  isTrial(plan: IPlan) {
    return plan && plan.nickname.includes('trial');
  },

  isSilverPlan(plan: IPlan) {
    return plan && plan.nickname.includes('silver');
  },

  getPlanEndDate(plan: IPlan) {
    const intervals: any = plan.interval.toLowerCase() + 's';
    return moment().add(plan.intervalCount, intervals).toDate();
  },

  cleanStorage() {
    sessionStorage.removeItem(TOKEN);
    localStorage.removeItem(TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    sessionStorage.removeItem(REFRESH_TOKEN);
    sessionStorage.removeItem('exp');
    localStorage.removeItem('exp');
  },

  hasToken() {
    return localStorage.getItem(TOKEN);
  },

  getPlanInfo(planNickname) {
    const info = {
      planDescription: 'Suite Platinum Trial',
      appsIncluded: 'Lectora® Online, Lectora® Desktop, ReviewLink™, AssetLibrary (In-App), AssetLibrary (Full Access), Cenario VR'
    };

    switch(planNickname) {
      case 'elb_platinum_suite_trial': {
        info.planDescription = 'Suite Platinum Trial';
        info.appsIncluded = 'Lectora® Online, Lectora® Desktop, ReviewLink™, AssetLibrary (In-App), AssetLibrary (Full Access), Cenario VR';
        break;}
      case 'elb_gold_suite_trial': {
        info.planDescription = 'Suite Gold Trial';
        info.appsIncluded = 'Lectora® Online, Lectora® Desktop, ReviewLink™, AssetLibrary (In-App), AssetLibrary (Full Access)';
        break;
      }
      case 'elb_silver_suite_trial': {
        info.planDescription = 'Suite Silver Trial';
        info.appsIncluded = 'Lectora® Online, ReviewLink™, AssetLibrary (In-App)';
        break;
      }
      case 'elb_platinum_suite': {
        info.planDescription = 'Suite Platinum';
        info.appsIncluded = 'Lectora® Online, Lectora® Desktop, ReviewLink™, AssetLibrary (In-App), AssetLibrary (Full Access), Cenario VR';
        break;
      }
      case 'elb_gold_suite': {
        info.planDescription = 'Suite Gold';
        info.appsIncluded = 'Lectora® Online, Lectora® Desktop, ReviewLink™, AssetLibrary (In-App), AssetLibrary (Full Access)';
        break;
      }
      case 'elb_silver_suite': {
        info.planDescription = 'Suite Silver';
        info.appsIncluded = 'Lectora® Online, ReviewLink™, AssetLibrary (In-App)';
        break;
      }
    }
    return info;
  },

  capitalizeTxt(txt: string) {
    return txt.charAt(0).toUpperCase() + txt.slice(1); // or if you want lowercase the rest txt.slice(1).toLowerCase();
  },

  getTrialRemainingDays(trialExpiration) {
    return moment(this.getLocalDate(trialExpiration)).diff(moment(), 'days');
  },

  getLocalDate(timestamp) {
    return moment.utc(timestamp).local();
  },

  isDateAfterToday(date) {
    return moment(moment()).isAfter(date, 'day'); //
  },

  isDateInNextDays(date, days) {
    return moment().diff(date, 'days') > -days;
  },

  formatDate(date, format) {
    return moment(date).format(format);
  },

  getYear() {
    return moment().year();
  },

  _throttle(fieldName, func, milli) {

    if (waiting[fieldName]) {
waiting[fieldName].token.cancel = true;
}
    const waitForASec = utils.wait(milli, null);
    waitForASec['token'] = { cancel: false };
    waitForASec.then(() => {
      if (!waitForASec['token'].cancel) {
        func();
        waiting[fieldName] = null;
      }
    });
    waiting[fieldName] = waitForASec;
  },

  throttle(id, func, milli) {
    if (throttleList[id]) {
      throttleList[id].cancel = true;
    }
    const waitForASec = this.wait(milli);
    waitForASec.cancel = false;
    waitForASec.then(() => {
      if (!waitForASec.cancel) {
        func();
        delete throttleList[id];
      } else {
        console.log('old throttle, nothing to do');
      }
    });
    throttleList[id] = waitForASec;
  },

  formatType(type) {
    if (type === 'reseller_unlimited') {
      return 'Unlimited';
    } else if (type === 'reseller_per_unit') {
      return 'Per Seat';
    }

    return type;
  },

  isResellerChildOrg(organization: IOrganization) {
    if (organization) {
      return organization.parentId;
    }

    return false;
  },

  isResellerOrg(organization: IOrganization) {
    if (organization) {
      return !organization.parentId && organization.type && organization.type.includes('reseller');
    }

    return false;
  },

  removeAllAfter(value, needle) {
    const pos = value.indexOf(needle);
    return value.substring(0, pos !== -1 ? pos : value.length);
  },

  ellipsisLongText(str, maxChar) {
    if (str.length > maxChar) {
      const newText = str.substr(0, maxChar);
      return newText + '...';
    }
    return str;
  },

  doesResellerHasFullMngt(reseller: IOrganization) {
    if (reseller.settings) {
      const found = reseller.settings.find((s) => s.key === 'allow-full-mngt');
      if (found && found.value === 'true') {
        return true;
      }
    }

    return false;
  },

  launchNewTab(link) {
    window.open(link, '_blank');
  },

  joinArrayWithSeparator(array, separator) {
    let string = '';
    for (const element of array) {
      string += element + separator;
    }
    return string.substring(0, string.length - 1); // Remove the trailing pipe character.
  },

  removeAfterLastUnderscore(str) {
    const lastUnderscoreIndex = str.lastIndexOf('_');
    return lastUnderscoreIndex !== -1 ? str.slice(0, lastUnderscoreIndex) : str;
  },

  isNullOrEmpty(str: string) {
    return str == null || str === '';
  }

};


