export interface ISetting {
  id?: number;
  key?: string;
  value?: string;
  type?: string;
  description?: string;
  displayValue?: string;
  defaultSetting?: boolean;
  onlyAdmin?: boolean;
  visibleToUser?: boolean;
}

export class Setting implements ISetting {
  constructor(
    public id?: number,
    public key?: string,
    public value?: string,
    public type?: string,
    public description?: string,
    public displayValue?: string,
    public defaultSetting?: boolean,
    public onlyAdmin?: boolean,
    public visibleToUser?: boolean
  ) {}
}
