var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("ribbon"),
      _vm._v(" "),
      _c("jhi-navbar"),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-main",
                [
                  _c(
                    "v-container",
                    {
                      staticClass: "px-4 py-0 fill-height",
                      attrs: { fluid: "" },
                    },
                    [
                      _c(
                        "v-row",
                        { staticClass: "fill-height" },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "transition",
                                { attrs: { name: "fade" } },
                                [
                                  _c("router-view", {
                                    key: _vm.$route.fullPath,
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("jhi-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }