import { ICertificateRequest } from '@/shared/model/certificate-request.model';
import axios from 'axios';

const baseApiUrl = 'api/internal';

export default class CertificateService {

  public retrieveTemplates(): Promise<any> {

    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/certificate-templates?langKey.equals=en&sort=name,asc`, { headers: {'X-ELB-KEY': 'certificate'} })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public create(entity: ICertificateRequest, apiKey: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/certificates`, entity,  {headers: {'X-ELB-KEY': apiKey}})
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public find(id: number, apiKey: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/certificates/${id}`, {headers: {'X-ELB-KEY': apiKey}})
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public isJobCompleted(id: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/certificates/async-jobs/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
