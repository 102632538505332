import { Component, Inject, Vue } from 'vue-property-decorator';
import AcceptanceService from './acceptance.service';

@Component
export default class AcceptanceMixin extends Vue {
  @Inject('acceptanceService') protected acceptanceService: () => AcceptanceService;

  public showLegal: boolean = this.$store.getters.showLegal;
  public legalContentList: any = this.$store.getters.legalContentList;

  public getLegalFromStore() {
    this.showLegal = this.$store.getters.showLegal;
    return this.showLegal;
  }

  public getLegalContentFromStore() {
    this.legalContentList = this.$store.getters.legalContentList;
    return this.legalContentList;
  }

  public getLatestFromService() {
    return this.acceptanceService().getLatest();
  }

}
