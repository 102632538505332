import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { ResourceInterface } from '@/shared/component/resource.interface';
import { INotification } from '@/shared/model/notification.model';

const baseApiUrl = 'api/private/notifications';

export default class NotificationService implements ResourceInterface {
    public find(id: number): Promise<INotification> {
        return new Promise<INotification>((resolve, reject) => {
            axios
            .get(`${baseApiUrl}/${id}`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
        });
    }

    public retrieve(paginationQuery?: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
          axios
            .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
    }

    public delete(id: number): Promise<any> {
        return new Promise<any>((resolve, reject) => {
          axios
            .delete(`${baseApiUrl}/${id}`)
            .then((res) => {
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
        });
    }

    public create(entity: INotification): Promise<INotification> {
        return new Promise<INotification>((resolve, reject) => {
          axios
            .post(`${baseApiUrl}`, entity)
            .then((res) => {
              resolve(res.data);
            })
            .catch((err) => {
              reject(err);
            });
        });
    }

    public update(entity: INotification): Promise<INotification> {
        return new Promise<INotification>((resolve, reject) => {
            axios
            .put(`${baseApiUrl}`, entity)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
        });
    }


}
