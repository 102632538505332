import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { IApplicationUser } from '@/shared/model/application-user.model';
import { ResourceInterface } from '@/shared/component/resource.interface';

const baseApiUrl = 'api/private/application-users';

export default class ApplicationUserService implements ResourceInterface {
  public find(id: number): Promise<IApplicationUser> {
    return new Promise<IApplicationUser>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public findByUserId(id: number): Promise<IApplicationUser> {
    const paginationQuery = {
      page: 0,
      size: 1,
      sort: ['id,asc'],
      predicate : [
        {name: 'userId.equals', value: id}
      ]
    };


    return new Promise<any>((resolve, reject) => {
      this.retrieve(paginationQuery)
      .then((res) => {
        resolve(res.data[0]);
      })
      .catch((err) => {
        reject(err);
      });
    });

  }

  public count(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios.get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public export(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
     // axios.post(baseApiUrl + `/subscriptions`, dto,  {responseType: 'blob'} )
        .get(baseApiUrl + `/export?${buildPaginationQueryOpts(paginationQuery)}`,  {responseType: 'blob'})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public create(entity: IApplicationUser): Promise<IApplicationUser> {
    return new Promise<IApplicationUser>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          if (err.response && err.response.data) {
            reject(err.response.data);
          } else {
            reject(err);
          }
        });
    });
  }

  public resendActivationEmail(entity: IApplicationUser): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      axios
        .post(`api/private/account/${entity.userId}/email`, entity)
        .then(() => resolve(true))
        .catch(() => {
          reject(false);
        });
    });
  }

  public update(entity: IApplicationUser): Promise<IApplicationUser> {
    return new Promise<IApplicationUser>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }


  public getActivationKey(entity: IApplicationUser, user: string): Promise<any> {
    let version = '21';
    entity.productVersions.forEach((element) =>  {
      if (element.name === 'Lectora Desktop') {
        version = element.version;
      }
    });

    return new Promise<any>((resolve, reject) => {
      axios
        .post('api/private/activation-key', {username: entity.email, desktopVersion: version, osUser: user})
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public removeActivationKey(entity: IApplicationUser, code: string): Promise<any> {

    return new Promise<any>((resolve, reject) => {
      axios
        .put(`api/private/activation-key/${code}`, {username: entity.email})
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }


  public retrieveUserActivity(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`api/private/user-activities?${buildPaginationQueryOpts(paginationQuery)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
