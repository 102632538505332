var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "div",
                    [
                      _c(
                        "v-avatar",
                        { attrs: { size: "32" } },
                        [
                          _vm.userAccount && _vm.userAccount.imageUrl
                            ? _c("img", {
                                attrs: { src: _vm.userAccount.imageUrl },
                              })
                            : _c("v-icon", { attrs: { color: "accent" } }, [
                                _vm._v("mdi-account-outline"),
                              ]),
                        ],
                        1
                      ),
                      _vm._v("  \n        "),
                      _c("span", { staticClass: "shrink hidden-sm-and-down" }, [
                        _c("strong", [_vm._v(_vm._s(_vm.currentUser))]),
                      ]),
                      _vm._v("   \n        "),
                      _c(
                        "v-icon",
                        _vm._g(_vm._b({}, "v-icon", attrs, false), on),
                        [_vm._v("mdi-chevron-down")]
                      ),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "v-card",
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "ml-4" },
                        [
                          _c("v-img", {
                            attrs: {
                              alt: "ELB Learning Logo",
                              "max-width": "30",
                              "max-height": "21.4",
                              src: "/content/images/email/elb_logo_black.png",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { attrs: { align: "end" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-n4 entity-button-text",
                              attrs: { text: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.logoutClicked()
                                },
                              },
                            },
                            [_vm._v("\n              Sign Out\n            ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-avatar",
                            { attrs: { size: "100" } },
                            [
                              _vm.userAccount && _vm.userAccount.imageUrl
                                ? _c("img", {
                                    attrs: { src: _vm.userAccount.imageUrl },
                                  })
                                : _c("v-icon", { attrs: { color: "accent" } }, [
                                    _vm._v("mdi-account-outline"),
                                  ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.userAccount
                        ? _c(
                            "v-col",
                            [
                              _c(
                                "v-row",
                                [
                                  _c("v-col", [
                                    _c("h5", [_vm._v(_vm._s(_vm.currentUser))]),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticClass: "mt-n6" },
                                [
                                  _c("v-col", [
                                    _vm._v(_vm._s(_vm.userAccount.email)),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticClass: "mt-n6" },
                                [
                                  _c(
                                    "v-col",
                                    _vm._l(
                                      _vm.userAccount.authorities,
                                      function (role, i) {
                                        return _c("span", { key: role }, [
                                          _vm._v(
                                            _vm._s(i > 0 ? ", " : "") +
                                              "\n                    "
                                          ),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.getRoleName(role))
                                            ),
                                          ]),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { staticClass: "mt-n4" },
                                [
                                  _c("v-col", [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "hand",
                                        on: {
                                          click: _vm.accountSettingsClicked,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  Account Settings\n                "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-card-actions",
                { staticClass: "ma-0 px-2" },
                [_c("v-spacer")],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }