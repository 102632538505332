import { Store } from 'vuex';
import VueRouter from 'vue-router';
import TranslationService from '@/locale/translation.service';
import StorageService from '@/shared/storage/storage.service';
import axios from 'axios';

export default class AccountService {
  constructor(private store: Store<any>, private translationService: TranslationService, private storage: StorageService, private router: VueRouter) {
    this.init();
  }

  public init(): void {
    this.retrieveProfiles();
  }

  public retrieveProfiles(): void {
    axios.get('management/info').then((res) => {
      if (res.data && res.data.activeProfiles) {
        this.store.commit('setRibbonOnProfiles', res.data['display-ribbon-on-profiles']);
        this.store.commit('setActiveProfiles', res.data['activeProfiles']);
      }
    });
  }

  public async retrieveAccount(): Promise<boolean> {
    return new Promise((resolve) => {
      axios
        .get('api/private/account')
        .then((response) => {
          this.store.commit('authenticate');
          const account = response.data;
          if (account) {
            this.store.commit('authenticated', account);
            if (this.store.getters.currentLanguage !== account.langKey) {
              this.store.commit('currentLanguage', account.langKey);
            }
            // if (localStorage.getItem('requested-url')) {
            //   this.router.replace(localStorage.getItem('requested-url'));
            //   localStorage.removeItem('requested-url');
            // }
          } else {
            this.store.commit('logout');
            this.router.push('/');
          }
          this.translationService.refreshTranslation(this.store.getters.currentLanguage);
          resolve(true);
        })
        .catch(() => {
          this.store.commit('logout');
          resolve(false);
        });
    });
  }

  public upgradeInPlace() {
    this.store.commit('setUpgrading', true);
    setTimeout(() => {
      this.store.commit('setUpgrading', false);
      // this.store.dispatch('refresh')
      // .then(() => { window.$nuxt.$router.push('/start') })
    }, 15000);
  }

  public async hasAnyAuthorityAndCheckAuth(authorities: any): Promise<boolean> {
    if (typeof authorities === 'string') {
      authorities = [authorities];
    }

    if (!this.authenticated || !this.userAuthorities) {
      const token = this.storage.getToken();
      if (!this.store.getters.account && !this.store.getters.logon && token) {
        return await this.retrieveAccount();
      }
    }

    return this.checkAuthorities(authorities);


    // for (let i = 0; i < authorities.length; i++) {
    //   if (this.userAuthorities.includes(authorities[i])) {
    //     return new Promise(resolve => {
    //       resolve(true);
    //     });
    //   }
    // }

    // return new Promise(resolve => {
    //   resolve(false);
    // });
  }

  private checkAuthorities(authorities: string[]): boolean {
    if (this.userAuthorities) {
      for (const authority of authorities) {
        if (this.userAuthorities.includes(authority)) {
          return true;
        }
      }
    }
    return false;
  }

  public get authenticated(): boolean {
    return this.store.getters.authenticated;
  }

  public get getIsTrial(): boolean {
    return this.store.getters.account.isTrial ? this.store.getters.account.isTrial : false;
  }

  public get userAuthorities(): any {
    return this.store.getters.account ? this.store.getters.account.authorities : [];
  }

  public needsToCompleteCheckout(): boolean {
    return this.store.getters.account && this.store.getters.account.checkoutStep && this.store.getters.account.checkoutStep === 'register';
  }

  public showLegal(): boolean {
    // return false;
    return true/* && this.store.getters.showLegal*/;
    // return this.store.getters.account && this.store.getters.account.legalVersionId && this.store.getters.account.legalVersionId !== '0';
  }

  public isAdmin() {
    return this.userAuthorities.includes('ROLE_ADMINISTRATOR') ||  this.userAuthorities.includes('ROLE_ADMIN');
  }

  public isReporter() {
    return this.userAuthorities.includes('ROLE_REPORTER');
  }


  public isOrgAdmin() {
    return this.userAuthorities.includes('ROLE_ADMINISTRATOR_ORG');
  }

  public isFulfilment() {
    return this.userAuthorities.includes('ROLE_FULFILLMENT');
  }

  public isFulfilmentReadOnly() {
    return this.userAuthorities.includes('ROLE_FULFILLMENT_RO');
  }

  public isAccountManager() {
    return this.userAuthorities.includes('ROLE_ACCOUNT_MNGR');
  }

  public isAuthor() {
    return this.userAuthorities.includes('ROLE_AUTHOR');
  }

  public isStudentUser() {
    return this.userAuthorities.includes('ROLE_USER') || this.userAuthorities.includes('ROLE_STUDENT');
  }

  public getUserOrganizationId(): any {
    return this.store.getters.account.organizationId;
  }

  public getUserId(): any {
    return this.store.getters.account.id;
  }

  public getUserOrganizationName(): string {
    return this.store.getters.account.organizationName;
  }

  public getSubscriptions(): string {
    if (this.store.getters.account) {
      return this.store.getters.account.subscriptions;
    }

    return null;
  }

  public getToken() {
    return this.storage.getToken();
  }

  public getRefreshToken() {
    return this.storage.getRefreshToken();
  }

  public canSelectOrganization() {
    return this.userAuthorities.includes('ROLE_ADMINISTRATOR') ||
          this.userAuthorities.includes('ROLE_FULFILLMENT') ||
          this.userAuthorities.includes('ROLE_FULFILLMENT_RO');

  }

  public canChangeALOptions() {
    return this.userAuthorities.includes('ROLE_ADMINISTRATOR') ||
          this.userAuthorities.includes('ROLE_FULFILLMENT');
  }

  public isReseller() {
    return this.userAuthorities.includes('ROLE_RESELLER');
  }
}
