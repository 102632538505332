import {Vue} from 'vue-property-decorator';
import Component from 'vue-class-component';
import EncodeService from '@/entities/encode/encode-service';
import {NavigationGuardNext, Route} from 'vue-router';
import Router from 'vue-router';
import {ErrorHandler, RawLocation} from 'vue-router/types/router';

@Component
export default class DecodeTokenMixin extends Vue {

  async decode(router: Router, to: Route, from: Route, next: NavigationGuardNext<Vue>, encodeService: EncodeService) {
    const jwtToken = to.query.encoded;
    if (jwtToken) {
      try {
        const decoded = await encodeService.decode(jwtToken);
        // Append the claims to the query parameters
        const query = {
          ...decoded
        };

        // temporarily replace the router's push method to prevent URL change
        const originalPush = router.push;
        // @ts-ignore
        router.push = (location: RawLocation,
                             onComplete?: (route: Route) => void,
                             onAbort?: ErrorHandler) => {
          originalPush.call(router, {
            path: to.path,
            query
          }, onComplete, onAbort);
        };

        // Proceed to the next route
        next();

        // Restore the original router.push method
        router.push = originalPush;
      } catch (error) {
        console.error('Invalid JWT Token', error);
        next(false); // or redirect to an error page
      }
    }
  }
}
