import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { IPaymentLink } from '@/shared/model/payment-link.model';

const baseApiUrl = 'api/private/payment-links';

export default class PaymentLinkService {
  public find(id: number): Promise<IPaymentLink> {
    return new Promise<IPaymentLink>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  }

  public findByKey(key: string): Promise<IPaymentLink> {
    return new Promise<IPaymentLink>((resolve, reject) => {
      axios
        .get(`api/public/payment-links/key/${key}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  }


  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public create(entity: IPaymentLink): Promise<IPaymentLink> {
    return new Promise<IPaymentLink>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public update(entity: IPaymentLink): Promise<IPaymentLink> {
    return new Promise<IPaymentLink>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public getAllCoupons(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/coupons`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  }

  public getHubSpotDeal(id): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
      .get(`api/private/hub-spot/deals/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  }


}
