import axios from 'axios';

export default class SigninService {
  public signin(data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post( 'api/public/authenticate', data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  }

  public ssoLogin(data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post( 'api/public/sso/auth', data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  }


  public createProvider(data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post( 'api/public/sso/provider', data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  }

  public saml(data: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post( 'doSaml', data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });

  }

}
