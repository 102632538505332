import axios from 'axios';

export default class AgreementService {
  public addAcceptance(orgId: number, userId: number): Promise<any> {
    const data = {
      userId,
      organizationId: orgId
    };
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`api/private/agreements/user-acceptance?userId=${userId}&organizationId=${orgId}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  // public getAcceptance(orgId: number, userId: number): Promise<any> {
  //   console.log('getAcceptance');
  //   console.log(orgId);
  //   console.log(userId);
  //   return new Promise<any>((resolve, reject) => {
  //     axios
  //       .get(`api/private/agreements/user-acceptance?userId${userId}&organizationId=${orgId}`)
  //       .then(res => {
  //         resolve(res);
  //       })
  //       .catch(err => {
  //         reject(err);
  //       });
  //   });
  // }

  public getLatest(): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get('api/private/agreements/latest')
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public exempt(organization: any, exempt: boolean): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .put('api/private/agreements/organizations/exempt?exemptStatus=' + exempt, organization)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public sendReminderToAdmins(orgId: number, userId: number): Promise<any> {
    const data = {
      userId,
      organizationId: orgId
    };
    return new Promise<any>((resolve, reject) => {
      axios
        .post('api/private/agreements/send-reminder', data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

}
