import axios from 'axios';

const encodeApiUrl = 'api/private/encode';
const decodeApiUrl = 'api/public/decode';

export default class EncodeService {

  public encode(body: { keyName: string; value: string }[]): Promise<string> {
    const formattedBody = {};
    body.forEach((b) => {
      formattedBody[b.keyName] = b.value;
    });
    return new Promise<string>((resolve, reject) => {
      axios
      .post(`${encodeApiUrl}`, formattedBody)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }

  public decode(token: string | string[]) {
    return new Promise<any>((resolve, reject) => {
      axios
      .get(`${decodeApiUrl}`,
        {
          params: {
            token
          }
        }
      )
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
    });
  }
}
