import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { ISchedulerJobInfo } from '@/shared/model/scheduler-Job-info.model';

const baseApiUrl = 'api/private/job-scheduler';

export default class JobSchedulerService {
  public find(id: string): Promise<ISchedulerJobInfo> {
    return new Promise<ISchedulerJobInfo>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public pause(job: ISchedulerJobInfo): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/pause`, job)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public resume(job: ISchedulerJobInfo): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/resume`, job)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public run(job: ISchedulerJobInfo): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/run`, job)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public delete(job: ISchedulerJobInfo): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/delete`, job)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public create(entity: ISchedulerJobInfo): Promise<ISchedulerJobInfo> {
    return new Promise<ISchedulerJobInfo>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public update(entity: ISchedulerJobInfo): Promise<ISchedulerJobInfo> {
    return new Promise<ISchedulerJobInfo>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
